import { GameScenario, GAME_STEP_TYPE } from "./types";

export const SCENARIO: GameScenario = {
    startStep: '1.2',
    steps: [
        {
            id: '1.2',
            type: GAME_STEP_TYPE.PAGE,
            pageId: 'page-11', //apteczka
            nextStep: '2.0'
        },
        {
            id: '2.0',
            type: GAME_STEP_TYPE.PAGE,
            pageId: 'page-14', // wprowadzenie o antyradarze
            nextStep: '2.1'
        },
        {
            id: '2.1',
            type: GAME_STEP_TYPE.PAGE,
            pageId: 'page-3', // pytanie o antyradar
            nextStep: '2.2'
        },
        {
            id: '2.2',
            type: GAME_STEP_TYPE.PAGE,
            pageId: 'page-7', //bezpieczny kierowca
            nextStep: '2.3'
        },
        {
            id: '2.3',
            type: GAME_STEP_TYPE.PAGE,
            pageId: 'page-1', //ride page
            nextStep: '7.1'
        },

        {
            id: '7.1',
            type: GAME_STEP_TYPE.GEO_STEP,
            position: {
                lat: 52.167077,
                lng: 21.085665
            },
            name: ["ul. Stanisława Kostki Potockiego 31"],
            nextStep: '7.2'
        },
        {
            id: '7.2',
            type: GAME_STEP_TYPE.PAGE,
            pageId: 'page-18', //quiz
            nextStep: '7.3'
        },
        {
            id: '7.3',
            type: GAME_STEP_TYPE.PAGE,
            pageId: 'page-23', //zarządzanie kryzysowe
            nextStep: '6.1'
        },
        {
            id: '6.1',
            type: GAME_STEP_TYPE.GEO_STEP,
            position: {
                lat: 52.150587,
                lng: 21.113915
            },
            name: ["Ruczaj 89-87"],
            nextStep: '6.2'
        },
        {
            id: '6.2',
            type: GAME_STEP_TYPE.PAGE,
            pageId: 'page-16', //quiz
            nextStep: '6.3'
        },
        {
            id: '6.3',
            type: GAME_STEP_TYPE.PAGE,
            pageId: 'page-5', //kto ważniejszy
            nextStep: '8.1'
        },
        {
            id: '8.1',
            type: GAME_STEP_TYPE.GEO_STEP,
            position: {
                lat: 52.147360,
                lng: 21.092272
            },
            name: ["Pałacowa 2"],
            nextStep: '8.2'
        },
        {
            id: '8.2',
            type: GAME_STEP_TYPE.PAGE,
            pageId: 'page-20', //quiz
            nextStep: '8.3'
        },
        {
            id: '8.3',
            type: GAME_STEP_TYPE.PAGE,
            pageId: 'page-4', //zepsute koło
            nextStep: '5.1'
        },
        {
            id: '5.1',
            type: GAME_STEP_TYPE.GEO_STEP,
            position: {
                lat: 52.146345,
                lng: 20.987576,
            },
            name: ["Aleja wyścigowa, znajdźcie granatowe Mitsubishi"],
            nextStep: '5.2'
        },
        {
            id: '5.2',
            type: GAME_STEP_TYPE.PAGE,
            pageId: 'page-12', //quiz
            nextStep: '5.3'
        },
        {
            id: '5.3',
            type: GAME_STEP_TYPE.PAGE,
            pageId: 'page-15', //pierwsza pomoc
            nextStep: '10.1'
        },
        {
            id: '10.1',
            type: GAME_STEP_TYPE.GEO_STEP,
            position: {
                lat: 52.130619,
                lng: 21.045199
            },
            name: ["Kazury"],
            nextStep: '10.2'
        },
        {
            id: '10.2',
            type: GAME_STEP_TYPE.PAGE,
            pageId: 'page-24', //quiz
            nextStep: '10.3'
        },
        {
            id: '10.3',
            type: GAME_STEP_TYPE.PAGE,
            pageId: 'page-21', //trening spostrzegawczości
            nextStep: '3.1'
        },
        {
            id: '3.1',
            type: GAME_STEP_TYPE.GEO_STEP,
            position: {
                lat: 52.165459,
                lng: 21.016029
            },
            name: ["Św. Katarzyna"],
            nextStep: '3.2'
        },
        {
            id: '3.2',
            type: GAME_STEP_TYPE.PAGE,
            pageId: 'page-8', //quiz
            nextStep: '3.3'
        },
        {
            id: '3.3',
            type: GAME_STEP_TYPE.PAGE,
            pageId: 'page-13', //agenci specjalni
            nextStep: '9.1'
        },
        {
            id: '9.1',
            type: GAME_STEP_TYPE.GEO_STEP,
            position: {
                lat: 52.196068,
                lng: 21.027263
            },
            name: ["Dominika Merliniego 2A, 02-511"],
            nextStep: '9.2'
        },
        {
            id: '9.2',
            type: GAME_STEP_TYPE.PAGE,
            pageId: 'page-22', //quiz
            nextStep: '9.3'
        },
        {
            id: '9.3',
            type: GAME_STEP_TYPE.PAGE,
            pageId: 'page-9', //wideolekcja
            nextStep: '9.4'
        },
        {
            id: '9.4',
            type: GAME_STEP_TYPE.PAGE,
            pageId: 'page-2', //wideolekcja quiz
            nextStep: '4.1'
        },
        {
            id: '4.1',
            type: GAME_STEP_TYPE.GEO_STEP,
            position: {
                lat: 52.201947,
                lng: 20.994710
            },
            name: ["ul. Juliana Kulskiego"],
            nextStep: '4.2'
        },
        {
            id: '4.2',
            type: GAME_STEP_TYPE.PAGE,
            pageId: 'page-10',//quiz
            nextStep: '4.3'
        },
        {
            id: '4.3',
            type: GAME_STEP_TYPE.PAGE,
            pageId: 'page-6', //systemy bezpieczeństwa
            nextStep: '11.1'
        },


        {
            id: '11.1',
            type: GAME_STEP_TYPE.GEO_STEP,
            position: {
                lat: 52.21293586933725,
                lng: 21.018887364418166
            },
            name: ["parking, Plac Unii"],
            nextStep: 'summary-page'
        },
        {
            id: 'summary-page',
            type: GAME_STEP_TYPE.PAGE,
            pageId: 'summary-page', //podsumowanie
            nextStep: null
        },
    ]
}
